import Home from '../views/pages/home';
import Resume from '../views/pages/resume';
import Skills from '../views/pages/skills';
import ContactMe from '../views/pages/contact-me';
import Projects from '../views/pages/projects';

const routes = {
  '/': Home, // default page
  '/home': Home,
  '/resume': Resume,
  '/skills': Skills,
  '/projects': Projects,
  '/contact': ContactMe,
};

export default routes;
