// regenerator-runtime merupakan tools agar async await dapat berjalan pada javascript lawas
import 'regenerator-runtime';
import '../styles/style.css';
import '../styles/responsive/responsive.css';
import App from './views/app';

const app = new App({
  menuButton: document.querySelector('#hamburgerButton'),
  content: document.querySelector('.portfolio-body__content'),
  navigation: document.querySelector('.portfolio-navigation'),
});

window.addEventListener('hashchange', () => {
  app.renderPage();
});

window.addEventListener('load', () => {
  app.renderPage();
});
