const Resume = {
  async render() {
    return `
        <section class="portfolio-body__content__resume">
            <h2>Resume and Certificate</h2>
            <section class="portfolio-body__content__resume__1">
            <div class="id-cv doc">
                <img src="./resume/id-cv.png" alt="indonesia resume" />
                <div class="overlay">
                <a
                    href="https://drive.google.com/file/d/1ocWqNGgPHOsAea_I9xrXo5ha2zxWe1CN/view?usp=drive_link"
                    target="_blank"
                    >Indonesia Resume</a
                >
                </div>
            </div>
            <div class="en-cv doc">
                <img src="./resume/en-cv.png" alt="english resume" />
                <div class="overlay">
                <a
                    href="https://drive.google.com/file/d/192fVYr5Ld3v_Ln_TWr6k-AUSL1Re7XxG/view?usp=drive_link"
                    target="_blank"
                    >English Resume</a
                >
                </div>
            </div>
            <div class="backend-certificate doc">
                <img
                src="./resume/be-dicoding-certificate.png"
                alt="backend dicoding certificate"
                />
                <div class="overlay">
                <a
                    href="https://drive.google.com/file/d/1l_-VOPwcHSbeGW397gIcRUcGv-r-t7Kl/view?usp=drive_link"
                    target="_blank"
                >
                    Back-End Dicoding Certificate
                </a>
                </div>
            </div>
            <div class="frontend-certificate doc">
                <img
                src="./resume/fe-dicoding-certificate.png"
                alt="frontend dicoding certificate"
                />
                <div class="overlay">
                <a
                    href="https://drive.google.com/file/d/14_tvPlxsB2SWadtQ05v7pkYrKdAC77hl/view?usp=drive_link"
                    target="_blank"
                >
                    Front-End Dicoding Certificate
                </a>
                </div>
            </div>
            <div class="supporting-certificate doc">
                <img
                src="./resume/be-idcamp-x-kadin-certificate.png"
                alt="idcamp x kadin certificate"
                />
                <div class="overlay">
                <a
                    href="https://drive.google.com/file/d/1HC0rlsdfdMEj8eH34yWIGSsgPbE7U3gk/view?usp=drive_link"
                    target="_blank"
                >
                    IDCamp X KADIN Training Certificate</a
                >
                </div>
            </div>
            </section>
        </section>
      `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
  },
};

export default Resume;
