const Skills = {
  async render() {
    return `
      <h2>Skills</h2>
      <section class="portfolio-body__content__skills">
        <div class="skills">
          <img src="./skills/html5.png" alt="html 5" />
          <div class="overlay">
            <p>HTML 5</p>
          </div>
        </div>
        <div class="skills">
          <img src="./skills/css3.png" alt="css 3" />
          <div class="overlay">
            <p>CSS 3</p>
          </div>
        </div>
        <div class="skills">
          <img src="./skills/javascript.png" alt="javascript" />
          <div class="overlay">
            <p>Javascript</p>
          </div>
        </div>
        <div class="skills">
          <img src="./skills/nodejs.png" alt="node js" />
          <div class="overlay">
            <p>Node.js</p>
          </div>
        </div>
        <div class="skills">
          <img src="./skills/postgresql.png" alt="postgresql" />
          <div class="overlay">
            <p>Postgresql</p>
          </div>
        </div>
        <div class="skills">
          <img src="./skills/ubuntu-server.png" alt="ubuntu server" />
          <div class="overlay">
            <p>Ubuntu Server</p>
          </div>
        </div>
        <div class="skills">
          <img src="./skills/docker.png" alt="docker" />
          <div class="overlay">
            <p>Docker</p>
          </div>
        </div>
      </section>
        `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
  },
};

export default Skills;
