const Projects = {
  async render() {
    return `
      <section class="portfolio-body__content__projects">
        <h2>Projects</h2>
        <div class="backend-projects">
          <h3>Back-End</h3>
          <section class="portfolio-body__content__projects__1">
            <div class="openmusic-api doc">
              <img src="./projects/openmusic-api.png" alt="openmusic-api" />
              <div class="overlay">
                <a
                  href="https://github.com/davidpinarto/Open-Music-API"
                  target="_blank"
                  >openmusic-api</a
                >
              </div>
            </div>
            <div class="bookshelf-api doc">
              <img src="./projects/bookshelf-api.png" alt="bookshelf-api" />
              <div class="overlay">
                <a
                  href="https://github.com/davidpinarto/Bookshelf-API"
                  target="_blank"
                  >bookshelf-api</a
                >
              </div>
            </div>
            <div class="forum-api doc">
              <img src="./projects/forum-api.png" alt="forum-api" />
              <div class="overlay">
                <a
                  href="https://github.com/davidpinarto/forum-api"
                  target="_blank"
                  >forum-api</a
                >
              </div>
            </div>
          </section>
        </div>
        <div class="frontend-projects">
          <h3>Front-End</h3>
          <section class="portfolio-body__content__projects__1">
            <div class="openmusic-api-docs doc">
              <img src="./projects/openmusic-api-docs.png" alt="openmusic-api-docs" />
              <div class="overlay">
                <a
                  href="https://openmusic-api-docs.dapi.my.id/"
                  target="_blank"
                  >openmusic-api-documentation</a
                >
              </div>
            </div>
            <div class="restaurant-app doc">
              <img src="./projects/restaurant-app.png" alt="restaurant-app" />
              <div class="overlay">
                <a
                  href="https://restaurant.dapi.my.id/"
                  target="_blank"
                  >restaurant-app</a
                >
              </div>
            </div>
            <div class="web-app-food doc">
              <img src="./projects/web-app-food.png" alt="web-app-food" />
              <div class="overlay">
                <a
                  href="https://web-app-food.dapi.my.id/"
                  target="_blank"
                  >web-app-food</a
                >
              </div>
            </div>
            <div class="bookshelf-app doc">
              <img src="./projects/bookshelf-app.png" alt="bookshelf-app" />
              <div class="overlay">
                <a
                  href="https://bookshelf-app.dapi.my.id/"
                  target="_blank"
                  >bookshelf-app</a
                >
              </div>
            </div>
            <div class="notes-react doc">
              <img src="./projects/notes-react.png" alt="notes-react" />
              <div class="overlay">
                <a
                  href="https://notes-react.dapi.my.id/"
                  target="_blank"
                  >notes-react</a
                >
              </div>
            </div>
            <div class="story-app doc">
              <img src="./projects/story-app.png" alt="story-app" />
              <div class="overlay">
                <a
                  href="https://story-app.dapi.my.id/"
                  target="_blank"
                  >story-app</a
                >
              </div>
            </div>
          </section>
        </div>
        <a
          href="https://github.com/davidpinarto?tab=repositories"
          target="_blank"
          >My Repository</a
        >
      </section>
          `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
  },
};

export default Projects;
