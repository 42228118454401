const API = require('../../config/config');

class EmailServices {
  static async sendEmail(data) {
    const response = await fetch(API.EmailService.sendEmail, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(data),
    });
    const responseJson = await response.json();
    return responseJson.message;
  }
}

module.exports = EmailServices;
