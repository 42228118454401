// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio-body__content__projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.portfolio-body__content__projects a {
  padding: 1rem;
  background-color: #20273c;
  border-radius: 1rem;
}

.portfolio-body__content__projects h3 {
  text-align: center;
  padding-bottom: 1rem;
}

.portfolio-body__content__projects__1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.portfolio-body__content h2 {
  text-align: center;
  padding: 1rem;
}

.portfolio-body__content__projects__1 div.doc {
  position: relative;
  border: 5px solid #20273c;
  border-radius: 1rem;
  height: 20rem;
}

.portfolio-body__content__projects__1 div:hover .overlay {
  opacity: 0.7;
  background-color: black;
}

.portfolio-body__content__projects__1 div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border: solid 1px white;
  transition: 1s;
  border-radius: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/pages/projects.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,uBAAuB;EACvB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".portfolio-body__content__projects {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.portfolio-body__content__projects a {\n  padding: 1rem;\n  background-color: #20273c;\n  border-radius: 1rem;\n}\n\n.portfolio-body__content__projects h3 {\n  text-align: center;\n  padding-bottom: 1rem;\n}\n\n.portfolio-body__content__projects__1 {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.portfolio-body__content h2 {\n  text-align: center;\n  padding: 1rem;\n}\n\n.portfolio-body__content__projects__1 div.doc {\n  position: relative;\n  border: 5px solid #20273c;\n  border-radius: 1rem;\n  height: 20rem;\n}\n\n.portfolio-body__content__projects__1 div:hover .overlay {\n  opacity: 0.7;\n  background-color: black;\n}\n\n.portfolio-body__content__projects__1 div img {\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n  border: solid 1px white;\n  transition: 1s;\n  border-radius: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
