// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio-body__content__skills {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.portfolio-body__content__skills .skills {
  background-color: #20273c;
  padding: 1rem;
  border-radius: 1rem;
}

.portfolio-body__content__skills div.skills {
  position: relative;
}

.portfolio-body__content__skills div:hover .overlay {
  opacity: 0.7;
  background-color: black;
  border: solid white 5px;
}

.portfolio-body__content__skills div img {
  height: 12rem;
  width: 100%;
  object-fit: scale-down;
  transition: 1s;
}
`, "",{"version":3,"sources":["webpack://./src/styles/pages/skills.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,cAAc;AAChB","sourcesContent":[".portfolio-body__content__skills {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.portfolio-body__content__skills .skills {\n  background-color: #20273c;\n  padding: 1rem;\n  border-radius: 1rem;\n}\n\n.portfolio-body__content__skills div.skills {\n  position: relative;\n}\n\n.portfolio-body__content__skills div:hover .overlay {\n  opacity: 0.7;\n  background-color: black;\n  border: solid white 5px;\n}\n\n.portfolio-body__content__skills div img {\n  height: 12rem;\n  width: 100%;\n  object-fit: scale-down;\n  transition: 1s;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
