const Home = {
  async render() {
    return `
        <section class="portfolio-body__content__home">
            <section class="portfolio-body__content__home__1">
            <p>Hi, i'm</p>
            <p class="portfolio-body__content__home__1_name">David Pinarto;</p>
            <p class="portfolio-body__content__home__1_dev">
                Back-End Developer
            </p>
            <br />
            <br />
            <p>
                I'm using Node.js to create a web server application and also
                RESTful API for Back-End application, and then I will use docker as my application
                container to run the application.
            </p>
            <p></p>
            </section>
        </section>
          `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
  },
};

export default Home;
