const NavigationInitiator = {
  init({ menuButton, navigation, content }) {
    menuButton.addEventListener('click', (event) => {
      this._toggleNavigation(event, navigation);
    });

    content.addEventListener('click', (event) => {
      this._closeNavigation(event, navigation);
    });

    navigation.addEventListener('click', (event) => {
      this._closeNavigation(event, navigation);
    });
  },

  _toggleNavigation(event, Navigation) {
    event.preventDefault();
    event.stopPropagation();
    Navigation.classList.toggle('open');
  },

  _closeNavigation(event, Navigation) {
    event.stopPropagation();
    Navigation.classList.remove('open');
  },
};

export default NavigationInitiator;
