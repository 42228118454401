const EmailServices = require('../../data/api/EmailServices');

const ContactMe = {
  async render() {
    return `
      <section class="portfolio-body__content__contact">
        <h3>Contact Me</h3>
        <div class="email-form">
          <form id="email-form">
            <div class="form-element">
              <label for="inputName">Name</label>
              <input
                id="inputName"
                type="text"
                name="name"
                placeholder="Your Name..."
                required
              />
              <br />
            </div>
            <div class="form-element">
              <label for="inputEmail">Email</label>
              <input
                id="inputEmail"
                type="text"
                name="senderEmail"
                placeholder="Your Email..."
                required
              />
              <br />
            </div>
            <div class="form-element">
              <label for="inputSubject">Subject</label>
              <input
                id="inputSubject"
                type="text"
                name="subject"
                placeholder="Subject..."
                required
              />
              <br />
            </div>
            <div class="form-element">
              <label for="inputMessage">Message</label>
              <textarea
                id="inputMessage"
                type="text"
                name="message"
                placeholder="Message..."
                required
              /></textarea>
              <br />
            </div>
            <div class="form-element">
              <button id="submitButton" type="submit" />Send Email</button>
            </div>
          </form>
        </div>

        <p id="messageAfterSubmit"></p>

        <div class="contact-me">
          <div class="telephone">
            <div class="logo">
              <i class="fa-brands fa-whatsapp"></i>
            </div>
            <p>+6285394276725</p>
          </div>
          <div class="github">
            <a href="https://github.com/davidpinarto" target="_blank">
              <i class="fa-brands fa-github"></i> Github
            </a>
          </div>
          <div class="email">
            <div class="logo">
              <i class="fa-solid fa-envelope"></i>
            </div>
            <p>davidpinarto90@gmail.com</p>
          </div>
          <div class="linkedin">
            <a
              href="https://www.linkedin.com/in/david-pinarto-89719228b/"
              target="_blank"
            >
              <i class="fa-brands fa-linkedin"></i> LinkedIn
            </a>
          </div>
        </div>
      </section>
          `;
  },

  async afterRender() {
    const submitAction = document.getElementById('email-form');

    submitAction.addEventListener('submit', async (event) => {
      event.preventDefault();
      document.getElementById('messageAfterSubmit').innerHTML = '<div class="loader"></div>';
      const inputName = document.getElementById('inputName').value;
      const inputEmail = document.getElementById('inputEmail').value;
      const inputSubject = document.getElementById('inputSubject').value;
      const inputMessage = document.getElementById('inputMessage').value;

      const data = {
        name: inputName,
        senderEmail: inputEmail,
        subject: inputSubject,
        message: inputMessage,
      };

      const response = await EmailServices.sendEmail(data);

      setTimeout(() => {
        document.getElementById('messageAfterSubmit').innerText = response;
      });
    });
  },
};

export default ContactMe;
