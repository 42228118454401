// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio-body__content__home {
  /* background-color: #151526; */
  padding: 1rem;
  border-radius: 1rem;
}

.portfolio-body__content__home__1_name {
  font-size: 2rem;
}

.portfolio-body__content__home__1_dev {
  font-size: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/pages/home.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".portfolio-body__content__home {\n  /* background-color: #151526; */\n  padding: 1rem;\n  border-radius: 1rem;\n}\n\n.portfolio-body__content__home__1_name {\n  font-size: 2rem;\n}\n\n.portfolio-body__content__home__1_dev {\n  font-size: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
