import NavigationInitiator from '../utils/NavigationInitiator';
import UrlParser from '../routes/url-parser';
import routes from '../routes/routes';

class App {
  constructor({ menuButton, navigation, content }) {
    this._menuButton = menuButton;
    this._content = content;
    this._navigation = navigation;

    this._initialAppShell();
  }

  _initialAppShell() {
    NavigationInitiator.init({
      menuButton: this._menuButton,
      content: this._content,
      navigation: this._navigation,
    });
  }

  async renderPage() {
    const url = UrlParser.parseActiveUrlWithCombiner();
    const page = routes[url];
    this._content.innerHTML = await page.render();
    await page.afterRender();
  }
}

export default App;
